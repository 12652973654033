import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store"

import "bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css'
import Popper from "vue3-popper";

import { FontAwesomeIcon } from './plugins/font-awesome'

createApp(App)
    .use(router)
    .use(store)
    .component("font-awesome-icon", FontAwesomeIcon)
    .component("Popper", Popper)
    .mount('#app')

import 'bootstrap/dist/js/bootstrap.js';