import axios from 'axios';

class AuthService {
    login(user) {
        return axios
            .post(process.env.VUE_APP_API_URL + 'login', {
                email: user.email,
                password: user.password
            })
            .then(response => {
                if (response.data.data.user.accessToken) {
                    console.log('login', response.data.data.user);
                    localStorage.setItem('user', JSON.stringify(response.data.data.user));
                }
                return response.data.data.user;
            });
    }

    logout() {
        localStorage.removeItem('user');
    }

    register(user) {
        return axios.post(process.env.VUE_APP_API_URL + 'register', {
            name: user.name,    
            email: user.email,
            password: user.password
        });
    }
}

export default new AuthService();
