<template>
  <div id="app">
    <nav class="navbar navbar-dark bg-dark fixed-top">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">{{title}}</a>

        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel" data-bs-scroll="true" data-bs-backdrop="false" >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">Welcome</h5>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          
          <div class="offcanvas-body">
            <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <router-link to="/" class="nav-link active" aria-current="page"  aria-label="Close"><span data-bs-dismiss="offcanvas">HOME</span></router-link>
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <router-link v-if="currentUser" to="/brands/list" class="nav-link"><span data-bs-dismiss="offcanvas">brands list</span></router-link>
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <router-link v-if="currentUser" to="/brand/create" class="nav-link"><span data-bs-dismiss="offcanvas">brand - create</span></router-link>
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <router-link v-if="currentUser" to="/providers" class="nav-link"><span data-bs-dismiss="offcanvas">Providers List</span></router-link>
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <router-link v-if="currentUser" to="/provider/create" class="nav-link"><span data-bs-dismiss="offcanvas">Provider - Create</span></router-link>
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas" >
                <router-link v-if="currentUser" to="/products" class="nav-link"><span >Products List</span></router-link>
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <router-link v-if="currentUser" to="/product/create" class="nav-link"><span data-bs-dismiss="offcanvas">product - Create</span></router-link>
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <router-link v-if="currentUser" to="/provider/assign-to-product" class="nav-link"><span data-bs-dismiss="offcanvas">product - assign to provider</span></router-link>
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas" >
                <router-link v-if="currentUser" to="/ingredients/list" class="nav-link"><span >Ingredients List</span></router-link>
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <router-link v-if="currentUser" to="/ingredient/create" class="nav-link"><span data-bs-dismiss="offcanvas">ingredient - Create</span></router-link>
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <router-link v-if="currentUser" to="/ingredient/assign-to-product" class="nav-link"><span data-bs-dismiss="offcanvas">ingredient - assign to provider</span></router-link>
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas" >
                <router-link v-if="currentUser" to="/integrated-offers/list" class="nav-link"><span >IntegratedOffers List</span></router-link>
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <router-link v-if="currentUser" to="/integrated-offer/create" class="nav-link"><span data-bs-dismiss="offcanvas">integrated offer - Create</span></router-link>
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <router-link v-if="currentUser" to="/integrated-offer/assign-ingredient" class="nav-link"><span data-bs-dismiss="offcanvas">integrated offer - assign ingredient</span></router-link>
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <router-link v-if="currentUser" to="/integrated-offer/assign-product" class="nav-link"><span data-bs-dismiss="offcanvas">integrated offer - assign product</span></router-link>
              </li>
              <div v-if="!currentUser" class="navbar-nav ml-auto">
                <li class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link to="/login" class="nav-link">
                    <font-awesome-icon icon="sign-in-alt" /><span data-bs-dismiss="offcanvas">Login</span>
                  </router-link>
                </li>
              </div>
              <div v-if="currentUser" class="navbar-nav ml-auto">
                <li class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link to="/profile" class="nav-link">
                    <font-awesome-icon icon="user" />
                    <span data-bs-dismiss="offcanvas">{{ currentUser.name }}</span>
                  </router-link>
                </li>
                <li class="nav-item" data-bs-dismiss="offcanvas">
                  <a href="#" class="nav-link" @click.prevent="logOut">
                    <font-awesome-icon icon="sign-out-alt" /> <span data-bs-dismiss="offcanvas">LogOut</span>
                  </a>
                </li>
              </div>
            </ul>
          </div>

        </div>

      </div>
    </nav>

    <div class="container" style="margin-top: 56px; border: 1px solid #f 00">
      <router-view />
    </div>

  </div>
</template>

<script>
export default {
  name: "AppVue",
  props: {

  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_ADMIN");
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_MODERATOR");
      }

      return false;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  data() {
    var title;
    if (process.env.NODE_ENV == "production") {
      title = "CiniIO";
    } else {
      title = process.env.NODE_ENV + " - CiniIO";
    }
    return {
      title,
    };
  },
};
</script>