import { createRouter, createWebHistory } from 'vue-router'
import Home from "../components/Home.vue"
import Login from "../components/Login.vue"
import Register from "../components/Register.vue"

// lazy-loaded
const Profile = () => import("../components/Profile.vue")
const BoardAdmin = () => import("../components/BoardAdmin.vue")
const BoardUser = () => import("../components/BoardUser.vue")
const BoardUserPost = () => import("../components/BoardUserPost.vue")

const Brands = () => import("../components/Brands.vue")
const BrandCreate = () => import("../components/BrandCreate.vue")

const Providers = () => import("../components/Providers.vue")
const ProviderCreate = () => import("../components/ProviderCreate.vue")
const ProviderAssignToProduct = () => import("../components/ProviderAssignToProduct.vue")

const Products = () => import("../components/Products.vue")
const Product = () => import("../components/Product.vue")
const ProductCreate = () => import("../components/ProductCreate.vue")
const ProductProvider = () => import("../components/ProductProvider.vue")

const Ingredients = () => import("../components/Ingredients.vue")
const Ingredient = () => import("../components/Ingredient.vue")
const IngredientAssignToProduct = () => import("../components/IngredientAssignToProduct.vue")
const IngredientProvider = () => import("../components/IngredientProvider.vue")
const IngredientCreate = () => import("../components/IngredientCreate.vue")

const IntegratedOffers = () => import("../components/IntegratedOffers.vue")
const IntegratedOffer = () => import("../components/IntegratedOffer.vue")
const IntegratedOfferCreate = () => import("../components/IntegratedOfferCreate.vue")
const IntegratedOfferAssignIngredient = () => import("../components/IntegratedOfferAssignIngredient.vue")
const IntegratedOfferAssignProduct = () => import("../components/IntegratedOfferAssignProduct.vue")
const IntegratedOfferPrice = () => import("../components/IntegratedOfferPrice.vue")

//check out this probably #DEPRECATED
const BoardModerator = () => import("../components/BoardModerator.vue")


const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: "/home",
        component: Home,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: "/register",
        component: Register,
    },
    {
        path: "/profile",
        name: "profile",
        // lazy-loaded
        component: Profile,
    },
    {
        path: "/admin",
        name: "admin",
        // lazy-loaded
        component: BoardAdmin,
    },
    {
        path: "/mod",
        name: "moderator",
        // lazy-loaded
        component: BoardModerator,
    },
    {
        path: "/user",
        name: "user",
        // lazy-loaded
        component: BoardUser,
    },
    {
        path: "/userpost",
        name: "userpost",
        // lazy-loaded
        component: BoardUserPost,
    },
    {
        path: "/brands/list",
        name: "brands-list",
        // lazy-loaded
        component: Brands,
    },
    {
        path: "/brand/create",
        name: "brand-create",
        // lazy-loaded
        component: BrandCreate,
    },
    {
        path: "/providers",
        name: "providers",
        // lazy-loaded
        component: Providers,
    },
    {
        path: "/provider/create",
        name: "providercreate",
        // lazy-loaded
        component: ProviderCreate,
    },
    {
        path: '/provider/assign-to-product',
        name: "provider-assign-to-product",
        component: ProviderAssignToProduct,
    },
    {
        path: '/product/:productId',
        name: "product",
        component: Product,
    },
    {
        path: "/products",
        name: "products",
        // lazy-loaded
        component: Products,
    },
    {
        path: "/product/create",
        name: "product-create",
        // lazy-loaded
        component: ProductCreate,
    },
    {
        path: '/product/:productId/provider/:providerId',
        name: "product-provider",
        component: ProductProvider,
    },
    {
        path: "/ingredients/list",
        name: "ingredients-list",
        // lazy-loaded
        component: Ingredients,
    },
    {
        path: '/ingredient/view/:ingredientId',
        name: "ingredient-view",
        component: Ingredient,
    },
    {
        path: "/ingredient/create",
        name: "ingredient-create",
        // lazy-loaded
        component: IngredientCreate,
    },
    {
        path: '/ingredient/assign-to-product',
        name: "ingredient-assign-to-product",
        component: IngredientAssignToProduct,
    },
    {
        path: '/ingredient/:ingredientId/provider/:providerId',
        name: "ingredient-provider",
        component: IngredientProvider,
    },
    {
        path: "/integrated-offers/list",
        name: "integrated-offers-list",
        // lazy-loaded
        component: IntegratedOffers,
    },
    {
        path: '/integrated-offer/view/:integratedOfferId',
        name: "integrated-offer-view",
        component: IntegratedOffer,
    },
    {
        path: "/integrated-offer/create",
        name: "integrated-offer-create",
        // lazy-loaded
        component: IntegratedOfferCreate,
    },
    {
        path: "/integrated-offer/assign-ingredient",
        name: "integrated-offer-assign-ingredient",
        // lazy-loaded
        component: IntegratedOfferAssignIngredient,
    },
    {
        path: "/integrated-offer/assign-product",
        name: "integrated-offer-assign-product",
        // lazy-loaded
        component: IntegratedOfferAssignProduct,
    },
    {
        path: "/integrated-offer-price/:integratedOfferId",
        name: "integrated-offer-price",
        // lazy-loaded
        component: IntegratedOfferPrice,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router
