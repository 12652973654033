import axios from 'axios';
import authHeader from './auth-header';

class UserService {
    getPublicContent() {
        console.log(process.env.VUE_APP_ENV_FILE);
        console.log(process.env.VUE_APP_ENV_LOCAL_FILE);
        console.log(process.env.VUE_APP_ENV_DEVELOPMENT_FILE);
        console.log(process.env.VUE_APP_ENV_DEVELOPMENT_LOCAL_FILE);
        return axios.get(process.env.VUE_APP_API_URL + 'ping');
    }

    getUserBoard() {
        return axios.get(process.env.VUE_APP_API_URL + 'me', { 
            headers: authHeader(),
        })
        // .then(console.log).catch(console.error)
        ;
    }

    getUserBoardPost() {
        return axios.post(
            process.env.VUE_APP_API_URL + 'mepost', //url
            {"param1" : "sarasa"}, //params
            { //options
                withCredentials: false,
                headers: authHeader(),
            }
        )
        // .then(console.log).catch(console.error)
        ;
    }

    getModeratorBoard() {
        return axios.get(process.env.VUE_APP_API_URL + 'mod', { headers: authHeader() });
    }

    getAdminBoard() {
        return axios.get(process.env.VUE_APP_API_URL + 'admin', { headers: authHeader() });
    }
}

export default new UserService();
