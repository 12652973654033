export default function authHeader() {
    let user = JSON.parse(localStorage.getItem('user'));
    
    if (user && user.accessToken) {
        return { 
            Authorization : 'Bearer ' + user.accessToken,
            // "Access-Control-Allow-Headers": true,
            // "Access-Control-Allow-Credentials": true,
            // "Content-Type": "application/json",
            // Accept: "application/json",
            // // "Content-Type": "application/x-www-form-urlencoded",
            // "Cache-Control": "no-cache",
        };
    } else {
        return {};
    }
}
